<template>
  <div>
    <b-alert :show="working" variant="info" class="d-flex align-items-center px-5">
      <strong>Working...</strong>
      <b-spinner class="ml-auto"></b-spinner>
    </b-alert>
    <div v-if="registration">
      <h4>Event Registration Details</h4>
      <h5>Participant: <router-link :to="{ name: 'participantDetails', params: { pid: registration.participant.id } }">{{ registration.participant.fullName }}</router-link><small> ({{ $moment(registration.datetime).diff(registration.participant.dob, 'years') }} y/o {{ registration.participant.gender }})</small></h5>
      <h5>Event: <router-link :to="{ name: 'eventDetails', params: { eid: registration.event.id } }">{{ registration.event.title }}</router-link></h5>
      <p>
        Date Registered: {{ $moment(registration.datetime).format('MMMM Do YYYY, h:mm a') }} 
        <template v-if="invoiceItem"> - <router-link :to="{ name: 'invoiceDetails', params: { iid: registration.invoice.id } }">${{ invoiceItem.price }}</router-link></template>
      </p>
      <p v-if="registration.request">
        Registrant Notes: {{ registration.request }}
      </p>
      <p v-if="registration.notes">
        Admin Notes: {{ registration.notes }}
      </p>
      <div v-if="registration.questionResponses" v-html="questionResponsesText" class="pb-3"></div>
      <b-btn-group class="float-right">
        <BtnPrint btnClass="" />
        <b-btn title="Edit the registration" variant="warning" :to="{ name: 'eventRegistrationForm', params: { rid: $route.params.rid }}">Edit</b-btn>
      </b-btn-group>
    </div>
    <p v-else-if="!working">
      No registration data loaded
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      working: false,
      registration: null,
      invoiceItem: null
    }
  },
  computed: {
    ...mapGetters([
      'eventQuestions'
    ]),
    questionResponsesText () {
      let text = "", 
      qRList = this.registration.questionResponses
      for (let qid in qRList) {
        let thisQuestionId = qid,
        thisQuestionRespId = qRList[qid]
        for (let q in this.eventQuestions) {
          if (this.eventQuestions[q].options.length) {
            for (let qro in this.eventQuestions[q].options) {
              if (thisQuestionId == this.eventQuestions[q].id && thisQuestionRespId == this.eventQuestions[q].options[qro].id) {
                text += '<div><b>' + this.eventQuestions[q].label + '</b>: ' + this.eventQuestions[q].options[qro].label + '</div>'
              }
            }
          } else {
            if (thisQuestionId == this.eventQuestions[q].id && thisQuestionRespId) {
              text += '<div><b>' + this.eventQuestions[q].label + '</b>: ' + thisQuestionRespId + '</div>'
            }
          }
        }
      }
      return text
    }
  },
  methods: {
    ...mapActions([
      'getEventRegistration',
      'getInvoiceItems',
      'getEventQuestions'
    ]),
    async doGetEventRegistration () {
       // this.working = true
      if (this.$route.params.rid) {
        let registration = await this.getEventRegistration(this.$route.params.rid)
        await this.getInvoiceItems({
          registration: this.$route.params.rid
        }).then(resp => {
          this.invoiceItem = resp[0]
        })
        this.registration = registration
      }
      // this.working = false
    }
  },
  mounted () {
    this.doGetEventRegistration()
    this.getEventQuestions()

  }
}
</script>
